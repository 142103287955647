<script>
  import Carousel from 'svelte-carousel'

  export let data
</script>

<Carousel
  autoplay
  autoplayDuration={5000}
  let:currentPageIndex
  let:pagesCount
  let:showPage
  let:showPrevPage
  let:showNextPage
>
  <div
    slot="prev"
    on:click={showPrevPage}
    class="custom-arrow custom-arrow-prev"
  />
  <div slot="dots" class="custom-dots">
    {#each Array(pagesCount) as _, pageIndex (pageIndex)}
      <div
        class="dot"
        on:click={() => showPage(pageIndex)}
        class:active={currentPageIndex === pageIndex}
      />
    {/each}
  </div>
  {#each data as imageObj}
    <div class="img-container">
      <img src={imageObj.src} alt={imageObj.label} /><br />
      <span>{imageObj.label}</span>
    </div>
  {/each}
  <div
    slot="next"
    on:click={showNextPage}
    class="custom-arrow custom-arrow-next"
  />
</Carousel>

<style>
  .img-container {
    align-items: center;
    vertical-align: bottom;
  }
  img {
    display: block;
    max-width: 800px;
    max-height: 400px;
    width: auto;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }
  span {
    display: block;
    text-align: center;
    font-size: 24px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .custom-dots {
    display: flex;
  }
  .dot {
    background-color: var(--blue-50);
    border-radius: 50%;
    width: 10px;
    height: 10px;
    margin: 5px;
  }

  .dot.active {
    background-color: var(--blue);
    border-radius: 50%;
    width: 10px;
    height: 10px;
    margin: 5px;
  }
</style>
