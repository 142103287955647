import Scorecard from './lib/plots/Scorecard.svelte'
import ImgCarousel from './lib/plots/ImgCarousel.svelte'
import { getData } from './lib/utils'

const carrouselData = [
  {
    src: '/static/images/storyboard/carouselOne.png',
    label: 'You add CA Notify to your phones',
  },
  {
    src: '/static/images/storyboard/carouselTwo.png',
    label:
      'When you are in close contact with other CA Notify users, your phones share anonymous codes',
  },
  {
    src: '/static/images/storyboard/carouselThree.png',
    label:
      'If one of those users tests positive for COVID-19, they can choose to notify others',
  },
  {
    src: '/static/images/storyboard/carouselFour.png',
    label:
      'You then receive an exposure notification with instructions on what to do next',
  },
  {
    src: '/static/images/storyboard/carouselFive.png',
    label:
      'If you test positive, you will receive a text message that will allow you to anonymously alert others',
  },
  {
    src: '/static/images/storyboard/carouselSix.png',
    label: 'If you are fully vaccinated, you can monitor for symptoms',
  },
  {
    src: '/static/images/storyboard/carouselSeven.png',
    label: 'You protect your community',
  },
]

new ImgCarousel({
  target: document.getElementById('carousel'),
  props: {
    data: carrouselData,
  },
})

getData().then((cardData) => {
  // SCORECARDS
  document.querySelectorAll('.scorecard').forEach((element) => {
    new Scorecard({
      target: element,
      props: {
        heading: element.dataset.heading,
        subheading: element.dataset.subheading,
        score: new Intl.NumberFormat().format(cardData[element.dataset.key]),
      },
    })
  })
})
