import * as d3 from 'd3'

export const backendUrl = process.env.VITE_BACKEND_URL || ''

export const getData = async () => {
  // Symptoms data
  const scores = await d3.csv(`${backendUrl}/static/data/scores.csv`)

  return scores[0]
}
