<script>
  export let id = 'scorecard'
  export let heading
  export let score
  export let subheading = ''
</script>

<div>
  <div class="score">{score}</div>
  <div class="heading">{heading}</div>
  {#if subheading !== ''}
    <div class="subheading">{subheading}</div>
  {/if}
</div>

<style lang="scss">
  .score {
    font-size: 36px;
    padding-bottom: 5%;
  }

  .heading {
    padding-top: 5%;
    font-size: 18px;
  }

  .subheading {
    padding-top: 5%;
    font-size: 12px;
  }
</style>
